import React from 'react'

import * as styles from './article-footer.module.css'

export default function ArticleFooter(props) {
    return (
        <>
          <div className={styles.articleFooter}>
            <div className={styles.articleFooterH4}>Did you find what you are looking for?</div>

            <button type="button" className={styles.opinionButton}>Yes, thanks!</button>
            <button type="button" className={styles.opinionButton}>Not really</button>
          </div>
        </>
    )
  }
