// extracted by mini-css-extract-plugin
export var adPlaceholder = "blog-post-module--adPlaceholder--d-oyO";
export var centeredAd = "blog-post-module--centeredAd--CL+2E";
export var hero = "blog-post-module--hero--fOUbS";
export var post_header = "blog-post-module--post_header--KkvLM";
export var sectionFullwidth = "blog-post-module--sectionFullwidth--C-Xqx";
export var svg1 = "blog-post-module--svg1--s9Zdz";
export var svg2 = "blog-post-module--svg2--bZmBb";
export var title = "blog-post-module--title--aQKt3";
export var toc = "blog-post-module--toc--+aFFF";
export var toc_list = "blog-post-module--toc_list--rR81E";