// extracted by mini-css-extract-plugin
export var authors = "article-header-module--authors--hUJKT";
export var avatar = "article-header-module--avatar--oLi8E";
export var avatarImg = "article-header-module--avatarImg--Q8f2m";
export var avatars = "article-header-module--avatars--9cX7b";
export var breadcrumb = "article-header-module--breadcrumb--6x0V0";
export var breadcrumb_link = "article-header-module--breadcrumb_link--MPWKg";
export var centeredAd = "article-header-module--centeredAd--sZiRl";
export var dFlex = "article-header-module--dFlex--ZSMAl";
export var dateBlock = "article-header-module--dateBlock--dkKsw";
export var hero = "article-header-module--hero--F7ohB";
export var mediaBody = "article-header-module--mediaBody--eWjz7";
export var pageHeader = "article-header-module--pageHeader--9HEqR";
export var pageSubHeader = "article-header-module--pageSubHeader--CJR5T";
export var promoBackground = "article-header-module--promoBackground--H8fKG";
export var promoContainer = "article-header-module--promoContainer--1rwhn";
export var svg = "article-header-module--svg--n0Vik";
export var svg1 = "article-header-module--svg1--GRF4g";
export var svg2 = "article-header-module--svg2--bbbu4";
export var title = "article-header-module--title--RyDif";