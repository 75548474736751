import React from 'react'

import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import { format } from 'date-fns'

import { getEntityUrl} from 'contentcurator-gatsby-template/src/lib/helpers'
import { getPageUrl } from 'contentcurator-gatsby-template/src/lib/helpers'

import Container from 'contentcurator-gatsby-template/src/components/container'

import * as styles from './article-header.module.css'


function Breadcrumb (props) {
    return (
      <div className={styles.breadcrumb}>
          <span>
              <Link className={styles.breadcrumb_link} to="/">Home</Link> 
              { // show site_section as long as no parent
                !props.thisPage.parent?.slug && props.thisPage.site_section?.slug && (<> &gt; <Link className={styles.breadcrumb_link} to={`/${props.thisPage.site_section?.slug}/`}>{props.thisPage.site_section?.section}</Link> </>)}
              { // show grandparent 
                    props.thisPage.parent?.include_parent_when_in_breadcrumb_as_parent && (<> &gt; <Link className={styles.breadcrumb_link} to={`/${props.thisPage.parent.parent.slug}/`}>{props.thisPage.parent.parent.title_short}</Link> </>)
              }
              { props.thisPage.parent?.slug && (<> &gt; <Link className={styles.breadcrumb_link} to={getPageUrl(props.thisPage.parent)}>{props.thisPage.parent.title_short ? props.thisPage.parent.title_short : props.thisPage.parent?.title}</Link> </>)}
              { props.thisPage?.title_short && (<> &gt; {props.thisPage?.title_short}</>)}
          </span>
      </div>
    )
  }


export default function ArticleHeader(props) {
    const article = props.article
    
    // author spacing
    var author_spacer = ""
    var author_spacer_last = ""
    if(article.authors.length === 2){
        author_spacer_last = " and "
    } else if (article.authors.length > 2) {
        author_spacer = ", "
        author_spacer_last = ", and "
    }

    return (
        <>
            <section className={styles.promoBackground}>
                <div className={styles.promoContainer}>
                    <div className={styles.dFlex}>
                        <div className={styles.hero}>
                            <Breadcrumb thisPage={article}></Breadcrumb>
                            <h1 className={styles.title}>{article.title}</h1>
                        </div>
                    </div>
                </div>

                <svg className={styles.svg} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1920 323" enableBackground="new 0 0 1920 323" space="preserve">
                    <polygon className={styles.svg1} fill="#ffffff" points="-0.5,322.5 -0.5,121.5 658.3,212.3 "></polygon>
                    <polygon className={styles.svg2} fill="#ffffff" points="-2,323 1920,323 1920,-1 "></polygon>
                </svg>
            </section>
            <div className={styles.pageHeader}>
                <Container>
                </Container>
            </div>
            
            <div className={styles.pageSubHeader}>
                {article.authors && article.authors.length>0 &&
                    
                    <div className={styles.authors}>
                        <div className={styles.avatars}>
                        {article.authors.map(entity => (
                            <>
                                {entity.main_image &&
                                    <span key={entity._id} className={styles.avatar}>
                                        <GatsbyImage className={styles.avatarImg} image={getImage(entity?.main_image?.imageFile)} alt={entity.main_image.alternativeText ? entity.main_image.alternativeText : "a nice image" } />
                                    </span>
                                }
                            </>
                        ))}
                        </div>
                        <div className={styles.mediaBody}>
                            By {article.authors.map((entity, index) => (
                                <>
                                    <Link key={entity._id} to={getEntityUrl(entity)}>{entity.name}</Link>
                                    { index<(article.authors.length-2) ? author_spacer:""}
                                    { index===(article.authors.length-2) ? author_spacer_last:""}
                                </>
                            ))}
                            <small className={styles.dateBlock}>
                                { article.created_at && !article.updated_at &&
                                    <>Published on {format( Date.parse(article.created_at), "MMMM do, yyyy")}</>
                                }
                                { article.updated_at && 
                                    <>Updated on {format( Date.parse(article.updated_at), "MMMM do, yyyy")}</>
                                }
                            </small>
                        </div>
                    </div>
                }
                { (!article.authors || (article.authors && article.authors.length===0)) &&
                    <small className={styles.dateBlock}>
                        { article.created_at && !article.updated_at &&
                            <>Published on {format( Date.parse(article.created_at), "MMMM do, yyyy")}</>
                        }
                        { article.updated_at && 
                            <>Updated on {format( Date.parse(article.updated_at), "MMMM do, yyyy")}</>
                        }
                    </small>
                }
                {article.authorities && article.authorities>0 && 
                    <ul>
                        {article.authorities.map(entity => (
                            <li key={entity._id}>
                                <Link to={getEntityUrl(entity)}>{entity.name}</Link>
                            </li>
                        ))}
                    </ul>
                }
            </div>
        
        </>
    )
  }